import React, {useState} from 'react';
import Main from './containers/Main/Main';
import Search from './containers/Search/Search';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import './scss/App.scss';
import MyReps from './containers/MyRepresentatives/MyRepresentatives';
import OurMission from './containers/OurMission/OurMission';
import SideDrawer from './components/Navigation/SideDrawer/SideDrawer';
import Heard from './containers/Heard/Heard';
import Backdrop from './components/Backdrop/Backdrop';
import { Contribute } from './containers/Contribute/contribute';



function App() {

  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showSideDrawer, setShowSideDrawer] = useState(false);

  function closeEventHandler(evt: React.MouseEvent) {
    setShowBackdrop(false);
    setShowSideDrawer(false);
  }

  function openEventHandler(evt: React.MouseEvent) {
    setShowBackdrop(true);
    setShowSideDrawer(true);
  }

  return (
    <Router>
      <Header sideDrawerButtonClicked={ (evt) => openEventHandler(evt) }/>
      <SideDrawer open={showSideDrawer} closed={(evt) => closeEventHandler(evt)}/>
      <Backdrop show={showBackdrop} clicked={(evt) => closeEventHandler(evt)}/>
      <Switch>
        <Route path="/" exact component={Main} />
        <Route path="/search" exact component={Search} />
        <Route path="/representatives" exact component={MyReps} />
        <Route path="/ourMission" exact component={OurMission} />
        <Route path="/heard" exact component={Heard} />
        <Route path="/contribute" exact component={Contribute} />
      </Switch>
      <Footer />
    </Router>
    
  );
}

export default App;
