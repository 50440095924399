import React, { MouseEventHandler } from 'react';

import './NavigationItems.scss';
import NavigationItem from './NavigationItem/NavigationItem';
import Logo from '../../Logo/Logo';

type NavigationItemsProps = {
    clicked?: MouseEventHandler
}

const navigationItems = (props: NavigationItemsProps) => (
    <React.Fragment>
        {/* <NavigationItem link="/" exact className='home-text' label='Home'></NavigationItem> */}
        <NavigationItem link="/" exact className='home-logo' clicked={props.clicked}><Logo /></NavigationItem>
        <NavigationItem link="/ourMission" label="Our Mission" clicked={props.clicked}></NavigationItem>
        <NavigationItem link="/representatives" label="My Representatives" clicked={props.clicked}></NavigationItem>
        <NavigationItem link="/search" label="Find &#38; Follow" clicked={props.clicked}></NavigationItem>
        <NavigationItem link="/heard" label="Engage &#38; Impact" clicked={props.clicked}></NavigationItem>
        <NavigationItem link="/contribute" label="Contribute" clicked={props.clicked}></NavigationItem>
    </React.Fragment>
);

export default navigationItems;