import * as React from 'react';
import './ResultList.scss';

interface IResultListProps<T> {  
  resultList: Array<T>;
  renderResult: (t:T) => React.ReactElement;
  /* Class names to include on the html element */
  classNames?: string;
  maxResults?: number;
}

class ResultList<T> extends React.Component<IResultListProps<T>> {

  static defaultProps = {
    classNames: "column"
  }

  public render(): JSX.Element {
    
    return (
      <div className={`_ResultList ${this.props.classNames || ""}`}>
        { this.props.resultList.map((t:T) => {
          return this.props.renderResult(t);
        })}
      </div>
    );
  }
}

export default ResultList;