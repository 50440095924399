



interface IContactProps {
  phone?: string;
  address?: string;
  twitter?: string;
  facebook?: string;
  instagram?: string;
  website?: string;
}

class Contact {
  props: IContactProps;

  constructor(props: IContactProps) {
    this.props = props;
  }

  get phone(): string {
    return this.props.phone || "";
  }
  get address(): string {
    return this.props.address || "";
  }
  get twitter(): string {
    return this.props.twitter || "";
  }
  get facebook(): string {
    return this.props.facebook || "";
  }
  get instagram(): string {
    return this.props.instagram || "";
  }
  get website(): string {
    return this.props.website || "";
  }
}

export default Contact;