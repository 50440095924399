import Representative from "../model/Representative";
import RepresentativeDistrict from "../model/RepresentativeDistrict";
import RepresentationCategory from "../model/RepresentationCategory";
import Bill, { BillStatus } from "../model/Bill";
import PoliticalParty from "../model/PoliticalParty";
import Contact from "../model/Contact";
/* photos */
import President from "../assets/official_photos/national/executive/President-Biden.jpg";
import VicePresident from "../assets/official_photos/national/executive/VicePresident-Harris.jpg";
import WIHouse from "../assets/official_photos/national/house/WI-5th-Sensenbrenner.jpg";
import WISenate1 from "../assets/official_photos/national/senate/WI-Johnson.png";
import WISenate2 from "../assets/official_photos/national/senate/WI-Baldwin.png";
import WIGovernor from "../assets/official_photos/state/WI/Governor-Evers.png";
import WIStateRep from "../assets/official_photos/state/WI/Representative-84th-Kuglitsch.jpg";
import WIStateSenate from "../assets/official_photos/state/WI/Senator-28th-Craig.jpg";
import CampaignStatus from "../model/CampaignStatus";
import BillCategory from "../model/BillCategories";

export const NationalDistrict: RepresentativeDistrict = 
  new RepresentativeDistrict({
    label: "United States",
    category: RepresentationCategory.NATIONAL
  });

export const WisconsinDistrict: RepresentativeDistrict = 
  new RepresentativeDistrict({
    label: "Wisconsin",
    category: RepresentationCategory.STATE
  });



export const NationalExecutiveRepresentatives: Representative[] = [
  new Representative({
    name: "Joe Biden",
    title: "President",
    district: NationalDistrict,
    photoUrl: President,
    politicalParty: PoliticalParty.DEMOCRAT,
    since: "2021",
    lastElected: "2020",
    contact: new Contact({
      website: "https://www.whitehouse.gov/",
      twitter: "https://twitter.com/whitehouse",
      facebook: "https://www.facebook.com/WhiteHouse/",
      instagram: "https://www.instagram.com/whitehouse/",
    })
  }),
  new Representative({
    name: "Kamala Harris",
    title: "Vice-President",
    district: NationalDistrict,
    photoUrl: VicePresident,
    politicalParty: PoliticalParty.DEMOCRAT,
    since: "2021",
    lastElected: "2020",
    contact: new Contact({
      website: "https://www.whitehouse.gov/",
      twitter: "https://twitter.com/whitehouse",
      facebook: "https://www.facebook.com/WhiteHouse/",
      instagram: "https://www.instagram.com/whitehouse/",
    })
  })
];

export const WisconsinRepresentatives: Representative[] = [
  new Representative({
    name: "Jim Sensenbrenner, Jr.",
    title: "US Representative",
    district: NationalDistrict,
    photoUrl: WIHouse,
    politicalParty: PoliticalParty.REPUBLICAN,
    since: "2003",
    lastElected: "2018",
    contact: new Contact({
      phone: "202-225-5101",
      website: "https://sensenbrenner.house.gov/",
      twitter: "https://twitter.com/@JimPressOffice"
    })
  }),
  new Representative({
    name: "Ron Johnson",
    title: "US Senate",
    district: NationalDistrict,
    photoUrl: WISenate1,
    politicalParty: PoliticalParty.REPUBLICAN,
    since: "2011",
    lastElected: "2016",
    contact: new Contact({
      phone: "202-224-5323",
      website: "https://www.ronjohnson.senate.gov/",
      twitter: "https://twitter.com/@SenRonJohnson"
    })
  }),
  new Representative({
    name: "Tammy Baldwin",
    title: "US Senate",
    district: NationalDistrict,
    photoUrl: WISenate2,
    politicalParty: PoliticalParty.DEMOCRAT,
    since: "2013",
    lastElected: "2018",
    contact: new Contact({
      phone: "202-224-5653",
      website: "https://www.baldwin.senate.gov/",
      twitter: "https://twitter.com/@SenatorBaldwin"
    })
  }),
];

export const MadisonWisconsinRepresentatives: Representative[] = [
  new Representative({
    name: "Tony Evers",
    title: "Governor",
    district: WisconsinDistrict,
    photoUrl: WIGovernor,
    politicalParty: PoliticalParty.DEMOCRAT,
    since: "2019",
    lastElected: "2018",
    contact: new Contact({
      phone: "608-266-1212",
      website: "https://evers.wi.gov/Pages/Home.aspx",
      twitter: "http://www.twitter.com/GovEvers",
      facebook: "http://www.facebook.com/GovernorTonyEvers",
    })
  }),
  new Representative({
    name: "David Craig",
    title: "State Senate",
    district: WisconsinDistrict,
    photoUrl: WIStateSenate,
    politicalParty: PoliticalParty.REPUBLICAN,
    since: "2011",
    lastElected: "2016",
    contact: new Contact({
      phone: "608-266-5400",
      website: "http://legis.wisconsin.gov/senate/28/craig",
      twitter: "https://twitter.com/SenDaveCraig",
      facebook: "https://www.facebook.com/SenatorDaveCraig/"
    })
  }),
  new Representative({
    name: "Mike Kuglitsch",
    title: "State Representative",
    district: WisconsinDistrict,
    photoUrl: WIStateRep,
    politicalParty: PoliticalParty.REPUBLICAN,
    since: "2017",
    lastElected: "2018",
    contact: new Contact({
      phone: "608-267-5158",
      website: "http://legis.wisconsin.gov/assembly/84/kuglitsch",
      facebook: "https://www.facebook.com/RepMikeKuglitsch/"
    })
  }),
];

export function randomCampaignStatus(bill: Bill): CampaignStatus {
  let randomTotal = Math.random() * 10000;
  let randomFor = randomTotal * Math.random();
  let randomAgainst = randomTotal - randomFor;
  let randomUndecided = Math.round(Math.random() * 1000);
  return new CampaignStatus({
    bill: bill,
    totalAgainst: Math.round(randomAgainst),
    totalFor: Math.round(randomFor),
    totalUndecided: randomUndecided,
    totalCampaigns: Math.round(randomTotal) + randomUndecided
  })
}

export const Bills: Bill[] = [
  new Bill({
    id: "H.R.4041",
    congress: "116th Congress (2019-2020)",
    name: "Clean Energy Victory Bond Act of 2019",
    summary: "To promote the domestic development and deployment of clean energy technologies required for the 21st century.",
    status: BillStatus.INTRODUCED,
    sponsor: "Rep. Lofgren, Zoe [D-CA-19]",
    committees: ["House - Ways and Means","Energy and Commerce", "Science, Space, and Technology"],
    actions: ["07/26/2019 Referred to the Subcommittee on Energy."],
    // text: hr4041text,
    categories: [BillCategory.ENERGY],
    pdfLink: new URL("https://www.congress.gov/116/bills/hr4041/BILLS-116hr4041ih.pdf")
  }),
  new Bill({
    id: "H.R.25",
    congress: "116th Congress (2019-2020)",
    name: "FairTax Act of 2019",
    summary: "To promote freedom, fairness, and economic opportunity by repealing the income tax and other taxes, abolishing the Internal Revenue Service, and enacting a national sales tax to be administered primarily by the States.",
    status: BillStatus.INTRODUCED,
    sponsor: "Rep. Woodall, Rob [R-GA-7]",
    committees: ["House - Ways and Means"],
    actions: ["01/03/2019 Referred to the House Committee on Ways and Means."],
    // text: hr4041text,
    categories: [BillCategory.TAXATION],
    pdfLink: new URL("https://www.congress.gov/116/bills/hr25/BILLS-116hr25ih.pdf")
  }),
  new Bill({
    id: "S.269",
    congress: "116th Congress (2019-2020)",
    name: "Clean Energy Victory Bond Act of 2019",
    summary: "To protect our Social Security system and improve benefits for current and future generations.",
    status: BillStatus.INTRODUCED,
    sponsor: "Sen. Blumenthal, Richard [D-CT]",
    committees: ["Senate - Finance"],
    actions: ["01/30/2019 Read twice and referred to the Committee on Finance."],
    // text: hr4041text
    categories: [BillCategory.SOCIAL_SECURITY],
    pdfLink: new URL("https://www.congress.gov/116/bills/s269/BILLS-116s269is.pdf")
  }),
  new Bill({
    id: "S.1129",
    congress: "116th Congress (2019-2020)",
    name: "Medicare for All Act of 2019",
    summary: "To establish a Medicare-for-all national health insurance program.",
    status: BillStatus.INTRODUCED,
    sponsor: "Sen. Sanders, Bernard [I-VT]",
    committees: ["Senate - Finance"],
    actions: ["04/10/2019 Read twice and referred to the Committee on Finance."],
    // text: hr4041text
    categories: [BillCategory.HEALTHCARE],
    pdfLink: new URL("https://www.congress.gov/116/bills/s1129/BILLS-116s1129is.pdf")
  }),
  new Bill({
    id: "S.2608",
    congress: "116th Congress (2019-2020)",
    name: "Advancing Competency-Based Education Act of 2019",
    summary: "To amend the Higher Education Act of 1965 to authorize competency-based education demonstration projects.",
    status: BillStatus.INTRODUCED,
    sponsor: "Sen. Hassan, Margaret Wood [D-NH]",
    committees: ["Senate - Health, Education, Labor, and Pensions"],
    actions: ["10/16/2019 Read twice and referred to the Committee on Health, Education, Labor, and Pensions."],
    // text: hr4041text
    categories: [BillCategory.EDUCATION],
    pdfLink: new URL("https://www.congress.gov/116/bills/s2608/BILLS-116s2608is.pdf")
  }),
  new Bill({
    id: "H.R.1121",
    congress: "116th Congress (2019-2020)",
    name: "Ensuring Quality in the Unemployment Insurance Program (EQUIP) Act",
    summary: "To amend title III of the Social Security Act to permit States to conduct substance abuse risk assessments and targeted drug testing as a condition for the receipt of unemployment benefits, and for other purposes.",
    status: BillStatus.INTRODUCED,
    sponsor: "Rep. Carter, Earl L. \"Buddy\" [R-GA-1]",
    committees: ["House - Ways and Means","House - Energy and Commerce"],
    actions: ["02/08/2019 Referred to the Subcommittee on Worker and Family Support."],
    // text: hr4041text
    categories: [BillCategory.EMPLOYMENT],
    pdfLink: new URL("https://www.congress.gov/116/bills/hr1121/BILLS-116hr1121ih.pdf")
  }),
]
