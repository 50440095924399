import React, {FunctionComponent, MouseEventHandler} from 'react';

import './NavigationItem.scss';
import { NavLink } from 'react-router-dom';

type NavigationItemProps = {
    link: string
    className?: string
    exact?: boolean
    label?: string
    clicked?: MouseEventHandler
}

const NavigationItem: FunctionComponent<NavigationItemProps> = ( props: React.PropsWithChildren<NavigationItemProps> ) =>
    <NavLink
        to={props.link}
        activeClassName="selected"
        exact={props.exact}
        onClick={props.clicked}
        className={`_NavigationItem ${props.className ? props.className: ""}`}>
            <div className="tab">
                {props.label ? props.label : null}
                {props.children}
            </div>
    </NavLink>

export default NavigationItem;