import React from 'react';
import logo from '../../assets/logo.png'
import SearchBar from '../../components/SearchBar/SearchBar'
import './Main.scss';

interface IMainProps {}
interface IMainState {
}

class Main extends React.Component<IMainProps, IMainState> { 

  render() {
    return (
      <div className="_Main">
        <div className="content">
          <div className="left">
            <div><img src={logo} alt="Get in Gov logo" /></div>
            <div className="tag">
              <div className="step1">Discover</div>
              <div className="step2">Follow</div>
              <div className="step3">Engage</div>
            </div>
            {/* <ul className="steps">
              <li className="active"><p>Discover</p></li>
              <li className="active"><p>Follow</p></li>
              <li className="active"><p>Engage</p></li>
              <li className="active"><p>Impact</p></li>
            </ul> */}
          </div>
          <div className="right">
            <SearchBar 
              placeholderText="Get Started"
              onChange={ (searchString: string) => (
                console.log(`New Search String ${searchString}`)
              )}
              />
          </div>
        </div>
      </div>
    );
  }
}

export default Main;


/*
<div className="content">
          <div className="main-about section">
            <div className="top">
              <div className="left">
                <h1>Get In Gov</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                  labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.
                </p>
              </div>
              <div className="right">
                <img src={capitol_building} alt="US Capitol Building" />
              </div>
            </div>
            <div className="bottom">
              <SearchBar 
                onChange={ (searchString: string) => (
                  console.log(`New Search String ${searchString}`)
                )}
              />
            </div>
          </div>
          <div className="main-getinvolved section">
            <div className="top">
              <h2 className="section-title">Get Involved</h2>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                labore et dolore magna aliqua.
              </div>
            </div>
            <div className="bottom">
              <Involvement
                title="Contact Representatives"
                description="Lorem ipsum dolor sit amet"
                classNames="contact"
                image={faCommentDots}
                route="contact" //TODO
              />
              <Involvement
                title="Track Legislation"
                description="Lorem ipsum dolor sit amet"
                classNames="contact"
                image={faSearch}
                route="track" //TODO
              />
              <Involvement
                title="Social Media Warrior"
                description="Lorem ipsum dolor sit amet"
                classNames="social"
                image={faBullhorn}
                route="social" //TODO
              />
            </div>
          </div>
          <div className="main-myreps section">
            <div className="top">
              <h2 className="section-title">My National Representatives</h2>
              <div>
                <Field
                  id="location"
                  label="My Location:"
                  placeholder="Enter Location..."
                  value="Kingston, Tennessee"
                />
              </div>
            </div>
            <div className="bottom">
              { 
                this.state.representatives.map((rep: Representative) => {
                  return <RepComp
                    representative={rep}
                  />
                })
              }
            </div>
          </div>
          <div className="main-donate section">
            <div className="left">
              <h2 className="section-title">Donate</h2>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.
              </div>
              <div>
                <button className="button">Make a Donation</button>
              </div>
            </div>
            <div className="right">
              <img src={donate} alt="Donate Today" />
            </div>
          </div>
        </div>*/