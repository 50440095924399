import BillCategory from "./BillCategories";
import { URL } from "url";


export enum BillStatus {
  INTRODUCED = "Introduced",
  PASSED_HOUSE = "Passed House",
  PASSED_SENATE = "Passed Senate",
  TO_PRESIDENT = "To President",
  BECAME_LAW = "Became Law"
}

interface IBillProps {
  id: string;
  congress: string;
  name: string;
  summary: string;
  sponsor: string;
  status: BillStatus;
  committees: string[];
  actions: string[];
  text?: string;
  categories: BillCategory[];
  pdfLink: URL;
}

class Bill {
  props: IBillProps;

  constructor(props: IBillProps) {
    this.props = props;
  }

  get id(): string {
    return this.props.id;
  }
  get congress(): string {
    return this.props.congress;
  }
  get name(): string {
    return this.props.name;
  }
  get summary(): string {
    return this.props.summary;
  }
  get status(): BillStatus {
    return this.props.status
  }
  get committees(): string[] {
    return this.props.committees;
  }
  get actions(): string[] {
    return this.props.actions;
  }
  get text(): string | null | undefined {
    return this.props.text;
  }
  get sponsor(): string {
    return this.props.sponsor;
  }
  latestAction(): string {
    return this.props.actions[0];
  }
  get categories(): BillCategory[] {
    return this.props.categories;
  }
  get pdfLink(): URL {
    return this.props.pdfLink;
  }
}

export default Bill;