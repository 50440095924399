import queryString from 'query-string'

export const Util = {
  // A custom hook that builds on useLocation to parse
  // the query string for you.
  searchParm: function(searchString: string, parm: string): string {
    console.log(`search -> ${searchString} || parm -> ${parm}`);
    var p = queryString.parse(searchString)[parm];
    console.log(`p -> ${p}`);
    if(Array.isArray(p)) return p[0];
    else if (p) return p;
    else return "";
  }
}



export default Util;