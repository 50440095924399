import Bill from "./Bill";


interface ICampaignProps {
  bill: Bill,
  totalCampaigns: number,
  totalFor: number,
  totalAgainst: number
  totalUndecided: number
}

class CampaignStatus {
  private props: ICampaignProps;

  constructor(props: ICampaignProps) {
    this.props = props;
  }
  
  public get bill() : Bill {
    return this.props.bill;
  }
  
  public get totalCampaigns() : number {
    return this.props.totalCampaigns;
  }
  
  public get totalFor() : number {
    return this.props.totalFor;
  }
  
  public get totalAgainst() : number {
    return this.props.totalAgainst;
  }

  
  public get totalUndecided() : number {
    return this.props.totalUndecided;
  }
  
}

export default CampaignStatus;