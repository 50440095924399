import * as React from 'react';
import Form from '../Form';
import { Field } from '../Field';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import './SearchBar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ISearchBarProps {
  initialValue?: string;
  placeholderText: string; //Start typing to search Keywords, Sponsor, or Categories...
  onChange?: (searchString: string) => void;
  classNames?: string;
}

const SearchBar: React.FunctionComponent<ISearchBarProps> = ({
  initialValue,
  placeholderText,
  onChange,
  classNames,
}) => {


    return (
        <Form
            action=""
            render={ () => (
                <React.Fragment>
                    <div className="icon">
                      <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <Field 
                      id="search" 
                      placeholder={placeholderText}
                      classNames="searchbar" 
                      value={initialValue}
                      onChange={ (evt: React.FormEvent<HTMLInputElement>) => (
                        onChange && onChange(evt.currentTarget.value)
                      )}
                    />
                </React.Fragment>
            )}
            classNames={`_SearchBar ${classNames || ""}`}
            submitButtonText="Search"
            hideSubmitButton={true}
        />
    );
}

export default SearchBar;