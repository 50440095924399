import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, IconDefinition, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Contact from '../../model/Contact';
import { faPhone, faHome } from '@fortawesome/free-solid-svg-icons';
import './ContactComp.scss';

interface IContactProps {  
  contact: Contact;

  /* Class names to include on the html element */
  classNames?: string;
}

class ContactComp extends React.Component<IContactProps> {

  private _resolve(prop: string, icon: IconDefinition, pretext: string, posttest: string): React.ReactFragment | void {
    if(prop && prop.length > 0) {
      return (
        <div className="contact-method">
          <Link to={ {pathname: prop} } target="_blank"> 
            {pretext} 
            <FontAwesomeIcon icon={icon} />
          </Link>
         {posttest}
        </div>
      )
    }
  }

  public render(): JSX.Element {
    
    return (
      <div className={`_ContactComp ${this.props.classNames || ""}`}>
        <div className="left">
          Contact: 
        </div>
        <div className="right">
          <div className="top">
            {this._resolve(this.props.contact.facebook, faFacebookF, "", "")}
            {this._resolve(this.props.contact.twitter, faTwitter, "", "")}
            {this._resolve(this.props.contact.instagram, faInstagram, "", "")}
            {this._resolve(this.props.contact.website, faHome, "", "")}
            {this._resolve(this.props.contact.phone, faPhone, "", "")}
          </div>
        </div>
      </div>
    );
  }
}

export default ContactComp;