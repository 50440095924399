import * as React from 'react';
import Logo from '../Logo/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBars } from '@fortawesome/free-solid-svg-icons'
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import './Header.scss';

interface IHeaderProps {
  sideDrawerButtonClicked: React.MouseEventHandler
}

class Header extends React.Component<IHeaderProps> {

  public render(): JSX.Element {
    return (
      <div className="_Header">
        <div className="content">
          <div className="smallScreen">
            <FontAwesomeIcon icon={faBars} className="menu" onClick={this.props.sideDrawerButtonClicked}/>
            <Logo />
          </div>
          <NavigationItems />
          <div className="header-avatar">
            <FontAwesomeIcon icon={faUser} className="avatar"/>
          </div>
        </div>
      </div>
    )
  };

}

export default Header;
