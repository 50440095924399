
import RepresentativeDistrict from "./RepresentativeDistrict";
import PoliticalParty from "./PoliticalParty";
import Contact from "./Contact";

interface IRepresentativeProps {
  name: string;
  title: string;
  district: RepresentativeDistrict;
  photoUrl: string;
  since: string;
  lastElected: string;
  politicalParty: PoliticalParty;
  contact: Contact;
}

class Representative {
  props: IRepresentativeProps;

  constructor(props: IRepresentativeProps) {
    this.props = props;
  }

  public get name(): string {
    return this.props.name;
  }
  public get title(): string {
    return this.props.title;
  }
  public get district(): RepresentativeDistrict {
    return this.props.district;
  }
  public get photoUrl(): string {
    return this.props.photoUrl;
  }
  public get since() : string {
    return this.props.since;
  }
  public get lastElected() : string {
    return this.props.lastElected;
  }
  public get politicalParty() : PoliticalParty {
    return this.props.politicalParty;
  }
  public get contact(): Contact {
    return this.props.contact;
  }
}

export default Representative;