import React, { FunctionComponent } from 'react';

import NavigationItems from '../NavigationItems/NavigationItems';
import './SideDrawer.scss';

interface SideDrawerProps {
    open: boolean
    closed: React.MouseEventHandler<HTMLDivElement>
}

const SideDrawer: FunctionComponent<SideDrawerProps> = ( props: SideDrawerProps ) => {
    var attachedClasses: Array<string> = ["_SideDrawer", (props.open ? "Open":"Close" )];
    return (
        <React.Fragment>
            <div className={attachedClasses.join(' ')}>
                <NavigationItems  clicked={props.closed}/>
            </div>
        </React.Fragment>
    );
};

export default SideDrawer;