import * as React from 'react';
import Representative from '../../model/Representative';
import ContactComp from '../ContactComp/ContactComp';
import './RepComp.scss';

interface IRepCompProps {  
  representative: Representative;

  /* Class names to include on the html element */
  classNames?: string;
}

class RepComp extends React.Component<IRepCompProps> {

  public render(): JSX.Element {
    
    return (
      <div className={`_RepComp ${this.props.classNames || ""}`} key={this.props.representative.name}>
        <div className={`photo ${this.props.representative.politicalParty}`}><img src={this.props.representative.photoUrl} alt={this.props.representative.name}/></div>
        <div className="text">
          <h3 className="name">{this.props.representative.name}</h3>
          <h4 className="title"><div>{this.props.representative.title}</div> <div>{this.props.representative.district.label}</div></h4>
          <div className="info">
            <div className="left">
              <div className={`party ${this.props.representative.politicalParty}`}>
              <div className="party-color" />{this.props.representative.politicalParty}</div>
              <ContactComp contact={this.props.representative.contact}/>
            </div>
            <div className="right">
              <div className="since">{this.props.representative.since && `Since: ${this.props.representative.since}`}</div>
              <div className="elected">{this.props.representative.lastElected && `Last Elected: ${this.props.representative.lastElected}`}</div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

export default RepComp;