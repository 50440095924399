import React from 'react';

import './Backdrop.scss';

interface IBackdropProps {
  show: boolean;
  clicked: React.MouseEventHandler<HTMLDivElement>;
}

const backdrop = (props: IBackdropProps) => (
  props.show ? <div className="_Backdrop" onClick={props.clicked}></div> : null
);

export default backdrop;