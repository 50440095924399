import React, { useEffect, useRef, useState } from 'react';
import './contribute.scss';
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { CreateOrderActions, CreateOrderData, OnApproveActions, OnApproveData } from '@paypal/paypal-js';
import CurrencyInput from 'react-currency-input-field';

type ButtonWrapperProps = {
  currency: string
}


export const Contribute: React.FC = () => {

  const currentAmount = useRef<string>("");
  const [successfulDonation, setSuccessfulDonation] = useState<boolean>(false);

  const ButtonWrapper = ({ currency }: ButtonWrapperProps) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    // eslint-disable-next-line
    }, [currency]);

 
     return (<PayPalButtons
        fundingSource="paypal"
        style={{"layout":"horizontal","label":"donate"}}
        disabled={false}
        createOrder={(data: CreateOrderData, actions: CreateOrderActions) => {
            return actions.order
                .create({
                    purchase_units: [
                        {
                            amount: {
                                value: currentAmount.current,
                                breakdown: {
                                    item_total: {
                                        currency_code: "USD",
                                        value: currentAmount.current,
                                    },
                                },
                            },
                            items: [
                                {
                                    name: "donation-example",
                                    quantity: "1",
                                    unit_amount: {
                                        currency_code: "USD",
                                        value: currentAmount.current,
                                    },
                                    category: "DONATION",
                                },
                            ],
                        },
                    ],
                })
                .then((orderId: string) => {
                    console.log(`OrderID created| ${orderId}`);
                    // Your code here after create the donation
                    return orderId;
                })
                .catch( err => {
                  console.error("Error creating order", err);
                  return "ERROR";
                });
        }}
        onApprove={async (data: OnApproveData, actions: OnApproveActions): Promise<void> => {
          console.log(`onApprove | ${data?.orderID}`)
          setSuccessfulDonation(true);
        }}
        onError={(err: any) => {
          console.error(err);
        }}
    />
     );
} 


  return (
    <div className="_Contribute">
        <div className="content">
          <div className="title">
            Contributions
          </div>
          <div className="subtitle">
            Help GetInGov to make a difference!
          </div>
          <div className="flexwrap">
            <div className="left">
              <div>
                GetInGov.com will have NO advertising, NO discussion groups, will NOT collect and aggregate data for any 
                third party, merely to show subscriber leanings on specific proposed legislation as it is developed.
              </div>
              <div>
                The only conversation we hope you will have is with the legislative body of your state and country!
              </div>
              <div>
                Please contribute to creating the software for the App:
              </div>
              <div className="bold">
                Goal: $45k
              </div>
              <div>
                Thanks, in advance for helping make this a reality!
              </div>
              <div className="center bold" style={{alignItems:"center"}}>
                Donation Amount: &nbsp; 
                <CurrencyInput 
                  id="donation-amount-input"
                  name="donation-amount"
                  placeholder="Please enter amount"
                  prefix='$'
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  onValueChange={(value, name) => currentAmount.current = value ?? ""}
                />
              </div>
              <div className="center">
                <PayPalScriptProvider
                    options={{
                        // "client-id": "sb",
                        "client-id": "AfoPhS7lh3dBSIqcaAcVwdx5NXNVBcxZbwwlx0rWFs4asARRVIrZ9A_K8UifLL2W2Z0-9b0w7dEhuLzL",
                        components: "buttons",
                        currency: "USD"
                    }}
                >
                    <ButtonWrapper
                        currency={"USD"}
                    />
                </PayPalScriptProvider>
              </div>
              {successfulDonation ? <div className="center">
                    Thank you for your donation!
              </div>: null}
            </div>
            <div className="right">
              Upcoming Contribution Options:
              <ul>
                <li>Subscription $1/year</li>
                <li>Saving for a PEOPLE's LOBBYIST $125k/per
                (For specific issues Pro or Con)</li>
                <li>Saving for PEOPLE's ATTORNEY $125k per
                (To write and submit NEW Bills for consideration)</li>
                <li>Engaging neighborhoods less well-served with WEB access and equipment.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  )
}