import React, { Component } from 'react';
import ResultList from '../../components/ResultList/ResultList';
import Bill from '../../model/Bill';
import BillSummary from '../../components/BillSummary/BillSummary';
import ModelServicesImpl from '../../model/IModelServices';
import {RouteComponentProps} from 'react-router-dom';
import Util from '../../util/Util';
import './Heard.scss';
import BillCategory from '../../model/BillCategories';



interface ISearchProps extends RouteComponentProps {
  
}

interface ISearchState {
  searchString: string;
  billTitle: boolean;
  billText: boolean;
  billSponsor: boolean;
  billCategory: string;
  myCampaigns: Bill[];
  trendingCampaigns: Bill[];
  newCampaigns: Bill[];
  showFilters: boolean;
}



class Heard extends Component<ISearchProps,ISearchState> {
  state: ISearchState = {
    searchString: Util.searchParm(this.props.location.search, "s"),
    billTitle: true,
    billText: true,
    billSponsor: true,
    billCategory: "",
    myCampaigns: [],
    trendingCampaigns: [],
    newCampaigns: [],
    showFilters: false
  }

  initiateNewSearch(searchString: string) {
    console.log(`initiate new search | ${searchString}`)
    // if(searchString.length < 3) return;
    ModelServicesImpl.search({
      searchString: searchString
    }).then(resp => {
      this.setState( (state) => {
        return {
          myCampaigns: resp.results,
        }
      })
    }
    )
    ModelServicesImpl.search({
      searchString: searchString
    }).then(resp => {
      this.setState( (state) => {
        return {
          trendingCampaigns: resp.results,
        }
      })
    }
    )
    ModelServicesImpl.search({
      searchString: searchString
    }).then(resp => {
      this.setState( (state) => {
        return {
          newCampaigns: resp.results,
        }
      })
    }
    )
  }

  componentDidMount() {
    this.initiateNewSearch(this.state.searchString);
  }

  resolveSearchCategories(): string[] {
    return [""].concat(Object.values(BillCategory));
  }

  render() {
    return (
      <div className="_Heard">
        <div className="content">
          <div className="title">
            Get Heard
          </div>
          <div className="subtitle">
            Use your voice to be heard on bills that are important to you!
          </div>
          <div className="tag">
            My Campaigns
          </div>
          <div className="my-campaign-results">
            <ResultList 
              classNames="row"
              resultList={this.state.myCampaigns}
              renderResult={ (bill: Bill) => (
                    <BillSummary
                      key={bill.id}
                      classNames="row"
                      bill={bill}
                      hideDetails={true}
                      chartDimensions={ {width: 200, height: 200} }
                    />
                )
              }
            />
          </div>
          <div className="tag">
            Trending Campaigns
          </div>
          <div className="trending-campaign-results">
            <ResultList 
                classNames="row"
                resultList={this.state.trendingCampaigns}
                renderResult={ (bill: Bill) => (
                      <BillSummary
                        key={bill.id}
                        classNames="row"
                        bill={bill}
                        hideDetails={true}
                        chartDimensions={ {width: 200, height: 200} }
                      />
                  )
                }
              />
            </div>
          <div className="tag">
            New Campaigns
          </div>
          <div className="new-campaign-results">
            <ResultList 
                classNames="row"
                resultList={this.state.newCampaigns}
                renderResult={ (bill: Bill) => (
                      <BillSummary
                        key={bill.id}
                        classNames="row"
                        bill={bill}
                        hideDetails={true}
                        chartDimensions={ {width: 200, height: 200} }
                      />
                  )
                }
              />
            </div>
        </div>
      </div>
    );
  }
}

export default Heard;
