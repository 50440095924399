import * as React from 'react';
import './BillSummary.scss';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label
} from 'recharts';
import Bill from '../../model/Bill';
import CampaignStatus from '../../model/CampaignStatus';
import ModelServicesImpl from '../../model/IModelServices';
import Dimension from '../../util/Dimension';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';;

interface IBillSummaryProps {  
  bill: Bill;
  /* Class names to include on the html element */
  classNames?: string;
  hideDetails: boolean;
  hideSharing: boolean;
  chartDimensions: Dimension;
}

interface IBillSummaryState {
  campaignStatus: CampaignStatus | null | undefined
}

class BillSummary extends React.Component<IBillSummaryProps, IBillSummaryState> {
  static defaultProps = {
    classNames: "column",
    hideDetails: false,
    hideSharing: false,
    chartDimensions: { width: 300, height: 250}
  }

  state: IBillSummaryState = {
    campaignStatus: null
  }
  
  componentDidMount(): void {
    ModelServicesImpl.campaignStatus({
      bill: this.props.bill
    }).then(resp => {
      this.setState( (state) => {
        return {
          campaignStatus: resp.campaignStatus,
        }
      })
    })
  }

  public render(): JSX.Element {
    return (
      <div className={`_BillSummary ${this.props.classNames || ""}`}>
        <div className="top">
          <div className="left">
            <h2 className="id">{this.props.bill.id}</h2>
            <h3 className="name">{this.props.bill.name}</h3>
            {this.props.hideDetails ? null : 
              <React.Fragment>
                <div className="congress">{this.props.bill.congress}</div>
                <table>
                  <tbody>
                  <tr className="row">
                      <td><div className="label">Category:</div></td>
                      <td><div className="text">{this.props.bill.categories[0]}</div></td>
                    </tr>
                    <tr className="row">
                      <td><div className="label">Sponsor:</div></td>
                      <td><div className="text">{this.props.bill.sponsor}</div></td>
                    </tr>
                    <tr className="row">
                      <td><div className="label">Committee:</div></td>
                      <td><div className="text">{this.props.bill.committees[0]}</div></td>
                    </tr>
                    <tr className="row">
                      <td><div className="label">Latest Action:</div></td>
                      <td><div className="text">{this.props.bill.latestAction()}</div></td>
                    </tr>
                    <tr className="row">
                      <td><div className="label">Bill Status:</div></td>
                      <td><div className="text">{this.props.bill.status}</div></td>
                    </tr>
                  </tbody>
                </table>
              </React.Fragment>
            } 
          </div>
          <div className="right">
          <div className="top">
            Campaign Activity 
          </div>
          <div className="bottom">
          {this.state.campaignStatus && 
            <BarChart width={this.props.chartDimensions.width} height={this.props.chartDimensions.height} data={[this.state.campaignStatus]}
              margin={{top: 20, right: 30, left: 20, bottom: 5}}>
              <Label value="Campaign Activity" position="top" />
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="name" hide={true}/>
              <YAxis/>
              <Tooltip/>
              <Bar dataKey="totalFor" stackId="a" fill="#53dd46" name="Total # For"/>
              <Bar dataKey="totalAgainst" stackId="a" fill="#cbdd46"  name="Total # Against"/>
              <Bar dataKey="totalUndecided" stackId="a" fill="#46ddd0"  name="Total # Undecided"/>
              <Bar dataKey="totalCampaigns" fill="#dd8546" name="Total # of Campaigns"/>
            </BarChart>
          }
          </div>
        </div>
        </div>
        <div className="bottom">
          <div className="label">
            <h2>Get Involved:</h2>
          </div>
          <div className="social-option">
            <div className="icon"><FontAwesomeIcon icon={faFacebookF} /></div>
            <div className="text">Facebook</div>
          </div>
          <div className="social-option">
            <div className="icon"><FontAwesomeIcon icon={faTwitter} /></div>
            <div className="text">Twitter</div>
          </div>
          <div className="social-option">
            <div className="icon"><FontAwesomeIcon icon={faInstagram} /></div>
            <div className="text">Instagram</div>
          </div>
          <div className="social-option">
            <div className="icon"><FontAwesomeIcon icon={faEnvelope} /></div>
            <div className="text">Email</div>
          </div>
        </div>
      </div>
    );
  }
}

export default BillSummary;