import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import './Footer.scss';

export function Footer(): JSX.Element {
  return (
    <div className="_Footer">
      <div className="top">
        <div className="social-option">
          <div className="icon"><FontAwesomeIcon icon={faFacebookF} /></div>
          <div className="text">Facebook</div>
        </div>
        <div className="social-option">
          <div className="icon"><FontAwesomeIcon icon={faTwitter} /></div>
          <div className="text">Twitter</div>
        </div>
        <div className="social-option">
          <div className="icon"><FontAwesomeIcon icon={faInstagram} /></div>
          <div className="text">Instagram</div>
        </div>
      </div>
      <div className="bottom">
        &#169; 2021
      </div>
    </div>
  );
      
    //   
    // </div>
  // ;
}

export default Footer;


/*
<div className="menu-list">
          <div className="title">Get In Gov</div>
          <div className="options">
            <div className="option">Our Mission</div>
            <div className="option">Our Team</div>
            <div className="option">Option3</div>
          </div>
        </div>
        <div className="menu-list">
          <div className="title">Get Involved</div>
          <div className="options">
            <div className="option">Contact Representatives</div>
            <div className="option">Track Legislation</div>
            <div className="option">Donate</div>
          </div>
        </div>
        <div className="menu-list">
          <div className="title">Social Media</div>
          <div className="options">
            <div className="option"><FontAwesomeIcon icon={faFacebook}/> Facebook</div>
            <div className="option"><FontAwesomeIcon icon={faTwitter}/> Twitter</div>
            <div className="option"><FontAwesomeIcon icon={faEnvelopeOpen}/> Web</div>
          </div>
        </div>
        <div className="social">

        </div>
        */