import * as DD from "../demo/DemoData"
import * as Protocol from "./Protocol";

interface IModelServices {
  representatives(request: Protocol.IRepresentativesRequest): Promise<Protocol.IRepresentativesResponse>;
  search(request: Protocol.ISearchRequest): Promise<Protocol.ISearchResponse>;
  campaignStatus(request: Protocol.ICampaignStatusRequest): Promise<Protocol.ICampaignStatusResponse>;
}

class DemoModelServices implements IModelServices {
  public representatives(request: Protocol.IRepresentativesRequest): Promise<Protocol.IRepresentativesResponse> {
    console.log(`new representatives search | ${request.location}`);
    let p = new Promise<Protocol.IRepresentativesResponse>( resolve => resolve({
      request: request,
      representatives: DD.NationalExecutiveRepresentatives.concat(DD.WisconsinRepresentatives).concat(DD.MadisonWisconsinRepresentatives)
    }));
    return p;
  }

  public search(request: Protocol.ISearchRequest): Promise<Protocol.ISearchResponse> {
    console.log(`new search | ${request.searchString}`);
    let billsCopy = [...DD.Bills];
    let b = billsCopy.sort( (a,b) =>  .5 - Math.random());
    let p = new Promise<Protocol.ISearchResponse>( resolve => resolve({
        request: request,
        results: b
      }));
    return p;
  }

  campaignStatus(request: Protocol.ICampaignStatusRequest): Promise<Protocol.ICampaignStatusResponse> {
    console.log(`new campaign status search | ${request.bill.id}`);
    let p = new Promise<Protocol.ICampaignStatusResponse>( resolve => resolve({
      request: request,
      campaignStatus: DD.randomCampaignStatus(request.bill)
    }));
    return p;
  }
}

const ModelServicesImpl: IModelServices = new DemoModelServices();
export default ModelServicesImpl;