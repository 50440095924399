import React from 'react';
import './OurMission.scss';

interface IMainProps {}
interface IMainState {
}

class OurMission extends React.Component<IMainProps, IMainState> { 

  render() {
    return (
      <div className="_OurMission">
        <div className="content">
          <div className="title">
            Our Mission
          </div>
          <div className="missionStatement">
            <p>
              GetInGov.com will create one platform (web app) where people can easily engage, follow, and participate with those who govern us on matters of importance to each of our own personal interests - both nationally and in our home state.
            </p>
            <p>
              Today, every state provides their session dates, work-in-process for proposed legislation, how our elected reps voted, and so much more. (Weblinks also provided at GetInGov.com.) All working Bills are listed both in a summary page and as complete text. 
            </p>
            <p>
              GetInGov.com will connect not only with our elected officials but other representatives in the senate and house (who may not be our representatives) yet are part of <i><u>working groups</u></i> (generally by subject matter) before they go to the floor to be voted on.
            </p>
            <p>
              GetInGov.com will provide simplicity so people can broadcast to other platforms matters of personal interest to groups, friends, family, and business colleagues, allowing naturally organic growth.
            </p>
            <p>
              Becoming vocal electronically fits our busy lives, allowing us to be active before Bills become Laws. Imagine how this convenience could change the political landscape in many areas such as education, national security, environment, taxation, health care, social security, infrastructure, energy, etc.
            </p>
            <p>
              By tracking and clearly illustrating how elected officials vote on every piece of legislation presented in every session, it will be evident to what degree delegates are representing the majority of their (GetInGov.com-participating) constituents.
            </p>
            <p>
              Having people engage early on and further working with our elected representatives to introduce new legislation could result in real power to move our sentiments collectively into the 21st century.
            </p>
            <p>
              GetInGov.com - Meaningful, simplified, political engagement.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default OurMission;