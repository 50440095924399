import React, { Component } from 'react';
import SearchBar from '../../components/SearchBar/SearchBar'
import { Field } from '../../components/Field';
import ResultList from '../../components/ResultList/ResultList';
import Bill from '../../model/Bill';
import BillSummary from '../../components/BillSummary/BillSummary';
import ModelServicesImpl from '../../model/IModelServices';
import {RouteComponentProps} from 'react-router-dom';
import Util from '../../util/Util';
import './Search.scss';
import BillCategory from '../../model/BillCategories';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';


interface ISearchProps extends RouteComponentProps {
  
}

interface ISearchState {
  searchString: string;
  billTitle: boolean;
  billText: boolean;
  billSponsor: boolean;
  billCategory: string;
  searchResults: Bill[];
  showFilters: boolean;
}



class Search extends Component<ISearchProps,ISearchState> {
  state: ISearchState = {
    searchString: Util.searchParm(this.props.location.search, "s"),
    billTitle: true,
    billText: true,
    billSponsor: true,
    billCategory: "",
    searchResults: [],
    showFilters: false
  }

  initiateNewSearch(searchString: string) {
    console.log(`initiate new search | ${searchString}`)
    // if(searchString.length < 3) return;
    ModelServicesImpl.search({
      searchString: searchString
    }).then(resp => {
      this.setState( (state) => {
        return {
          searchResults: resp.results,
        }
      })
    })
  }

  componentDidMount() {
    this.initiateNewSearch(this.state.searchString);
  }

  resolveSearchCategories(): string[] {
    return [""].concat(Object.values(BillCategory));
  }

  render() {
    return (
      <div className="_Search">
        <div className="content">
          <div className="title">
            Search
          </div>
          <div className="search-searchbar">
            <div className="top">
              <SearchBar 
                initialValue={this.state.searchString}
                placeholderText="Start typing to search..."
                onChange={ (s: string) => (
                  this.setState((state) => {
                    this.initiateNewSearch(s);
                    return {
                      searchString: s,
                      searchResults: []
                    }
                  })
                )}
                classNames="dark"
              />
            </div>
            <div className="bottom">
              <div className="filtersToggle">
                <div className="label">Toggle Filters</div>
                {
                  this.state.showFilters ? 
                    <FontAwesomeIcon icon={faChevronCircleDown} className="showFilter" size="xs" onClick={() => this.setState({showFilters: false})}/> :
                    <FontAwesomeIcon icon={faChevronCircleRight} className="showFilter" size="xs" onClick={() => this.setState({showFilters: true})}/>
                }
              </div>
              <div className={`filters ${this.state.showFilters ? "show":"hide"}`}>
                <Field 
                  id="billTitle"
                  label="Title of Bill"
                  shortLabel="Title"
                  value={this.state.billTitle}
                  editor="checkbox"
                  classNames="label-last"
                  onChange={ (e: React.FormEvent<HTMLInputElement>) => {
                    this.setState({
                      billTitle: e.currentTarget.checked
                    })
                  }}
                />
                <Field 
                  id="billText"
                  label="Text of Bill"
                  shortLabel="Text"
                  value={this.state.billText}
                  editor="checkbox"
                  classNames="label-last"
                  onChange={ e => (
                    this.setState({
                      billText: e.currentTarget.checked
                    })
                  )}
                />
                <Field 
                  id="billSponsor"
                  label="Sponsor of Bill"
                  shortLabel="Sponsor"
                  value={this.state.billSponsor}
                  editor="checkbox"
                  classNames="label-last"
                  onChange={ e => (
                    this.setState({
                      billSponsor: e.currentTarget.checked
                    })
                  )}
                />
                <Field 
                  id="billCategory"
                  label="Category of Bill"
                  shortLabel="Category"
                  value={this.state.billCategory}
                  editor="dropdown"
                  options={this.resolveSearchCategories()}
                  onChange={ e => (
                    this.setState({
                      billCategory: e.currentTarget.value
                    })
                  )}
                />
              </div>
            </div>
          </div>
          <div className="search-results">
            <h1>Search Results</h1>
            <ResultList 
              resultList={this.state.searchResults}
              renderResult={ (bill: Bill) => (
                  <React.Fragment key={bill.id}>
                    <BillSummary
                      bill={bill}
                    />
                  </React.Fragment>
                )
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Search;
