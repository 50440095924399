
enum BillCategory {
  EDUCATION = "Education",
  EMPLOYMENT = "Employment",
  ENERGY = "Energy",
  HEALTHCARE = "Healthcare",
  SOCIAL_SECURITY = "Social Security",
  TAXATION = "Taxation",
}

export default BillCategory;