import RepresentationCategory from "./RepresentationCategory";

interface IRepresentativeDistrict {
  label: string,
  category: RepresentationCategory
}

class RepresentativeDistrict {
  private props: IRepresentativeDistrict;

  constructor(props: IRepresentativeDistrict) {
    this.props = props;
  }
  
  public get label() : string {
    return this.props.label;
  }
  
  public get category() : RepresentationCategory {
    return this.props.category;
  }
  
  
}

export default RepresentativeDistrict;