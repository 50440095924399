import React from 'react';

import logoImg from '../../assets/logo.png';
import './Logo.scss';

function logo( ) {

  return (
      <img className="_Logo" src={logoImg} alt="Get In Gov logo" />
  );
};

export default logo;