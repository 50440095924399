import * as React from "react";
// import { IErrors } from "./Form";

/* The available editors for the field */
type Editor = "textbox" | "multilinetextbox" | "dropdown" | "checkbox";

export interface IFieldProps {
  /* The unique field name */
  id: string;

  /* The label text for the field */
  label?: string;

  /* The shortened label text for the field */
  shortLabel?: string;

  /* The placeholder text for the field */
  placeholder?: string;

  /* The editor for the field */
  editor?: Editor;

  /* The drop down items for the field */
  options?: string[];

  /* The field value */
  value?: any;

  /* Class names to include on the element */
  classNames?: String;

  onChange?: (event: React.FormEvent<any>) => void;
}

export const Field: React.FunctionComponent<IFieldProps> = ({
  id,
  label,
  shortLabel,
  placeholder,
  editor,
  options,
  value,
  classNames,
  onChange
}) => {
  return (
    <div className={`form-group ${classNames || ""}`}>
      {label && <label htmlFor={id} className="label">{label}</label>}
      {shortLabel && <label htmlFor={id} className="shortLabel">{shortLabel}</label>}

      {editor!.toLowerCase() === "textbox" && (
        <input
          id={id}
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={
            (e: React.FormEvent<HTMLInputElement>) =>
              onChange && onChange(e)
              // console.log(e) /* TODO: push change to form values */
          }
          onBlur={
            (e: React.FormEvent<HTMLInputElement>) =>
              console.log(e) /* TODO: validate field value */
          }
          className={`form-control`}
        />
      )}

      {editor!.toLowerCase() === "checkbox" && (
        <input
          id={id}
          type="checkbox"
          value={value}
          checked={value}
          onChange={
            (e: React.FormEvent<HTMLInputElement>) =>
              {onChange && onChange(e)}
          }
          onBlur={
            (e: React.FormEvent<HTMLInputElement>) =>
              console.log(e) /* TODO: validate field value */
          }
          className={`form-control ${classNames || ""}`}
        />
      )}

      {editor!.toLowerCase() === "multilinetextbox" && (
        <textarea
          id={id}
          value={value}
          onChange={
            (e: React.FormEvent<HTMLTextAreaElement>) =>
              {onChange && onChange(e)}
          }
          onBlur={
            (e: React.FormEvent<HTMLTextAreaElement>) =>
              console.log(e) /* TODO: validate field value */
          }
          className={`form-control ${classNames || ""}`}
        />
      )}

      {editor!.toLowerCase() === "dropdown" && (
        <select
          id={id}
          name={id}
          value={value}
          onChange={
            (e: React.FormEvent<HTMLSelectElement>) =>
              {onChange && onChange(e)}
          }
          onBlur={
            (e: React.FormEvent<HTMLSelectElement>) =>
              console.log(e) /* TODO: validate field value */
          }
          className={`form-control ${classNames || ""}`}
        >
          {options &&
            options.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
        </select>
      )}

      {/* TODO - display validation error */}
    </div>
  );
};


Field.defaultProps = {
  editor: "textbox",
  placeholder: "",
  onChange: (e) => {}
};