import React from 'react';
import ModelServicesImpl from "../../model/IModelServices"
import Representative from '../../model/Representative';
import RepresentationCategory from '../../model/RepresentationCategory';
import RepComp from '../../components/RepComp/RepComp';
import SearchBar from '../../components/SearchBar/SearchBar';
import './MyRepresentatives.scss';

interface IMyRepsProps {}
interface IMyRepsState {
  representatives: Representative[];
  location: string;
  searchString: string;
}

class MyReps extends React.Component<IMyRepsProps, IMyRepsState> {

  constructor(props: IMyRepsProps) {
    super(props);
    this.state = { representatives: [], location: "Madison, Wisconsin", searchString: ""}
  } 

  initiateNewSearch(searchString: string) {
    console.log(`initiate new search | ${searchString}`)
    if(searchString.length < 3) return;
    ModelServicesImpl
      .representatives({
        category: RepresentationCategory.NATIONAL, 
        location: searchString
      })
      .then(
        (result) => {
          this.setState({
            representatives: result.representatives,
            location: searchString
          })
        }
      )
  }

  componentDidMount() {
    this.initiateNewSearch(this.state.location)
  }

  render() {
    return (
      <div className="_MyReps">
        <div className="content">
          <div className="title">
            My Representatives
          </div>
          <div className="top">
            <SearchBar 
              placeholderText="Find Representatives"
              onChange= { (s: string) => (
                this.setState((location) => {
                  // this.initiateNewSearch(s);
                  return {
                    searchString: s
                  }
                })
              )}
              classNames="dark"
            />
            <h3>Representatives for {this.state.location}</h3>
          </div>
          <div className="bottom">
            { 
              this.state.representatives.map((rep: Representative) => {
                return <RepComp
                  representative={rep}
                  key={`${rep.district.label}_${rep.name}`}
                />
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

export default MyReps;


/*
<div className="content">
          <div className="main-about section">
            <div className="top">
              <div className="left">
                <h1>Get In Gov</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                  labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.
                </p>
              </div>
              <div className="right">
                <img src={capitol_building} alt="US Capitol Building" />
              </div>
            </div>
            <div className="bottom">
              <SearchBar 
                onChange={ (searchString: string) => (
                  console.log(`New Search String ${searchString}`)
                )}
              />
            </div>
          </div>
          <div className="main-getinvolved section">
            <div className="top">
              <h2 className="section-title">Get Involved</h2>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                labore et dolore magna aliqua.
              </div>
            </div>
            <div className="bottom">
              <Involvement
                title="Contact Representatives"
                description="Lorem ipsum dolor sit amet"
                classNames="contact"
                image={faCommentDots}
                route="contact" //TODO
              />
              <Involvement
                title="Track Legislation"
                description="Lorem ipsum dolor sit amet"
                classNames="contact"
                image={faSearch}
                route="track" //TODO
              />
              <Involvement
                title="Social Media Warrior"
                description="Lorem ipsum dolor sit amet"
                classNames="social"
                image={faBullhorn}
                route="social" //TODO
              />
            </div>
          </div>
          <div className="main-myreps section">
            <div className="top">
              <h2 className="section-title">My National Representatives</h2>
              <div>
                <Field
                  id="location"
                  label="My Location:"
                  placeholder="Enter Location..."
                  value="Kingston, Tennessee"
                />
              </div>
            </div>
            <div className="bottom">
              { 
                this.state.representatives.map((rep: Representative) => {
                  return <RepComp
                    representative={rep}
                  />
                })
              }
            </div>
          </div>
          <div className="main-donate section">
            <div className="left">
              <h2 className="section-title">Donate</h2>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.
              </div>
              <div>
                <button className="button">Make a Donation</button>
              </div>
            </div>
            <div className="right">
              <img src={donate} alt="Donate Today" />
            </div>
          </div>
        </div>*/